<template>
    <div v-bind:class="divClass" v-on:click="deleteFromCart(id)">
        <img src="/img/icon-bin.svg" class="delete-icon" />
    </div>
</template>

<script>
export default {
    name: "DeleteCart",
    props: {
        divClass: {
            default: "delete-item float-end"
        },
        id: {
            required: true
        }
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    methods: {
        deleteFromCart(id) {
            const self = this;
            const cartUUid = localStorage.getItem("cart_uuid");
            self.$http
                .delete(`${self.url}/cart/${cartUUid}?item_id=${id}`)
                .then(() => {
                    self.$eventHub.$emit("updateCart", true);
                });
        }
    }
};
</script>

<style scoped></style>
