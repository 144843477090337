<template>
    <div>
        <div
            class="cookie-overlay"
            v-if="cookieData && (!cookie.accepted || showCookie)"
        ></div>
        <!--<button
            class="cookie-button btn btn-dark"
            @click="showCookie = true"
            style="position: fixed;bottom:0;right:50px"
        >
            {{ cookieData.title }}
        </button>-->
        <div
            class="cookie-container fixed-bottom w-100 p-3 p-md-5"
            v-if="cookieData && (!cookie.accepted || showCookie)"
        >
            <div class="row align-items-center">
                <div class="col-md-8 mb-3 mb-md-0">
                    <div v-html="cookieData.lead"></div>
                    <a :href="cookieData.link">{{
                        $t("További információ")
                    }}</a>
                </div>
                <div
                    class="col-6 col-md-2 d-flex align-items-center justify-content-around"
                >
                    <button v-b-modal.cookieModal class="btn btn-secondary">
                        {{ $t("Részletek") }}
                    </button>
                </div>
                <div
                    class="col-6 col-md-2 d-flex align-items-center justify-content-around"
                >
                    <button class="btn btn-primary" v-on:click="saveAccepted()">
                        {{ $t("Elfogadás") }}
                    </button>
                </div>
            </div>
        </div>
        <b-modal
            :id="'cookieModal'"
            v-if="cookieData"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            size="xl"
            hide-footer
        >
            <div class="form-check mb-5">
                <input
                    type="checkbox"
                    disabled="disabled"
                    v-model="cookie.cookieDefault"
                    class="form-check-input"
                    id="cookieCheck1"
                />
                <label class="form-check-label" for="cookieCheck1">{{
                    cookieData.session_title
                }}</label>
                <div v-html="cookieData.session_description"></div>
                <div v-html="cookieData.description"></div>
            </div>
            <div class="form-check mb-5" v-if="cookieData.analytics_title">
                <input
                    type="checkbox"
                    v-model="cookie.cookieAnalytics"
                    class="form-check-input"
                    id="cookieCheck2"
                />
                <label class="form-check-label" for="cookieCheck2">{{
                    cookieData.analytics_title
                }}</label>
                <div v-html="cookieData.analytics_description"></div>
            </div>
            <div class="form-check mb-5" v-if="cookieData.marketing_title">
                <input
                    type="checkbox"
                    v-model="cookie.cookieMarketing"
                    class="form-check-input"
                    id="cookieCheck3"
                />
                <label class="form-check-label" for="cookieCheck3">{{
                    cookieData.marketing_title
                }}</label>
                <div v-html="cookieData.marketing_description"></div>
            </div>
            <!--<div class="d-flex align-items-center justify-content-between">
            <button class="btn btn-default" data-dismiss="modal" v-on:click="saveAccepted()">{{ $t('Elfogadás') }}</button>
            <button class="btn btn-elevate" data-dismiss="modal">{{ $t('Mégse') }}</button>
          </div>-->
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "Cookie",
    data() {
        return {
            cookieData: {},
            showCookie: false,
            cookie: {
                cookieAnalytics: true,
                cookieDefault: true,
                cookieMarketing: true,
                accepted: false,
                env: "production"
            }
        };
    },
    created() {
        this.loadCookieJson();
        if ($cookies.get("cookies")) {
            this.cookie = JSON.parse(atob($cookies.get("cookies")));
        }
    },
    computed: {
        pageLanguage: function() {
            return "hu";
        }
    },

    methods: {
        loadCookieJson() {
            const self = this;
            this.axios
                .get(
                    `${process.env.VUE_APP_APP_URL}/cookie/cookie-` +
                        this.pageLanguage +
                        ".json"
                )
                .then(response => {
                    self.cookieData = response.data;
                });
        },
        saveAccepted() {
            this.showCookie = false;
            this.cookie.accepted = true;
            this.cookie.cookieDefault = true;
            this.cookie.analitycsCode = this.cookieData.analytics_scripts;
            this.cookie.env = process.env.MIX_APP_ENV;
            $cookies.set(
                "cookies",
                btoa(JSON.stringify(this.cookie)),
                60 * 60 * 60 * 3600
            );
            window.location.reload();
        }
    }
};
</script>
