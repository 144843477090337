<template>
    <header>
        <div class="container">
            <div class="row align-items-center">
                <div class="col col-xl-2 order-2 order-xl-1">
                    <router-link :to="{ name: 'Home' }"
                        ><img src="/img/logo.svg" class="logo" />
                    </router-link>
                </div>
                <div class="col-3 col-xl-8 order-1 order-xl-2">
                    <div class="menu-open d-flex d-xl-none">
                        <img src="/img/menu.svg" />
                    </div>
                    <div class="menu-close d-none">X</div>
                    <ul class="navigation text-center" id="main-menu">
                        <template v-for="(item, index) in menu.items">
                            <li v-bind:key="'felso-menu-' + index">
                                <router-link :to="item.link">{{
                                    item.title
                                }}</router-link>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="col-3 col-xl-2 order-3">
                    <ul class="navigation">
                        <li class="position-relative">
                            <a
                                href="#"
                                class="c-modal-open"
                                v-on:click.prevent="openCustomModal"
                            >
                                <img
                                    src="/img/user-solid.svg"
                                    class="menu-icon d-block d-xl-none"
                                />
                                <span class="d-none d-xl-block">{{
                                    $t("Profil")
                                }}</span>
                            </a>
                            <div
                                class="custom-modal"
                                v-bind:class="{
                                    active: loggedInModal || loggedOutModal
                                }"
                            >
                                <!-- if not logged in-->
                                <div
                                    class="loggedout"
                                    v-if="!isAuthenticated && loggedOutModal"
                                >
                                    <form v-on:submit.prevent="doLogin">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div
                                                    class="form-floating mb-2"
                                                    id="email"
                                                >
                                                    <input
                                                        type="text"
                                                        name="text1"
                                                        class="form-control mb-2"
                                                        placeholder="E-mail"
                                                        v-model="form.email"
                                                    />
                                                    <label for="email">{{
                                                        $t("E-mail")
                                                    }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div
                                                    class="form-floating mb-2"
                                                    id="password"
                                                >
                                                    <input
                                                        type="password"
                                                        name="text1"
                                                        class="form-control mb-2"
                                                        :placeholder="
                                                            $t('Jelszó')
                                                        "
                                                        v-model="form.password"
                                                    />
                                                    <label for="password">{{
                                                        $t("Jelszó")
                                                    }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary w-100"
                                                >
                                                    {{ $t("Belépés") }}
                                                </button>
                                                <a
                                                    href="#"
                                                    class="small-text d-block my-3"
                                                    v-b-modal.forgotPassword
                                                    >{{
                                                        $t(
                                                            "Elfelejtettem a jelszavamat."
                                                        )
                                                    }}</a
                                                >
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row border-top pt-3">
                                        <div class="col-xl-12">
                                            <router-link
                                                :to="{ name: 'Registration' }"
                                                class="btn btn-secondary w-100"
                                                >{{
                                                    $t("Regisztráció")
                                                }}</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                                <!-- if not logged in end -->

                                <!-- if logged in-->
                                <div
                                    class="loggedin"
                                    v-if="isAuthenticated && loggedInModal"
                                >
                                    <router-link
                                        :to="{
                                            name: 'Profile',
                                            params: { tab: 'profile' }
                                        }"
                                        class="d-block my-3"
                                        >{{ $t("Profil adatok") }}</router-link
                                    >
                                    <router-link
                                        :to="{
                                            name: 'Profile',
                                            params: { tab: 'orders' }
                                        }"
                                        class="d-block my-3"
                                        >{{ $t("Megrendelések") }}</router-link
                                    >
                                    <!--<router-link
                                        :to="{
                                            name: 'Profile',
                                            params: { tab: 'newsletter' }
                                        }"
                                        class="d-block my-3"
                                        >{{ $t("Hírlevél") }}</router-link
                                    >-->

                                    <div class="row border-top pt-3">
                                        <div class="col-xl-12">
                                            <a
                                                href="#"
                                                v-on:click.prevent="logout"
                                                class="btn btn-secondary w-100"
                                                >{{ $t("Kilépés") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <!-- if logged in end -->
                            </div>
                        </li>
                        <li class="position-relative">
                            <a
                                href="#"
                                v-on:click.prevent="openCartModal"
                                class="c-modal-open d-flex align-items-center"
                            >
                                <img
                                    src="/img/shopping-cart-solid.svg"
                                    class="menu-icon d-inline-flex d-xl-none"
                                />
                                <span class="d-none d-xl-block">{{
                                    $t("Kosár")
                                }}</span>
                                <!-- if cart not empty -->
                                <strong
                                    class="text-orange ms-2"
                                    v-if="cartItems > 0"
                                    >{{ cartItems }}</strong
                                >
                                <!-- if cart not empty end -->
                            </a>
                            <div
                                class="custom-modal"
                                v-bind:class="{ active: cartOpen }"
                            >
                                <!-- if not logged in-->
                                <div
                                    class="mini-cart text-start"
                                    v-if="cartOpen"
                                >
                                    <p v-if="cart.items.length < 1">
                                        {{
                                            $t(
                                                "Jelenleg nincs termék a kosaradban."
                                            )
                                        }}
                                    </p>
                                    <template
                                        v-for="(item, index) in cart.items"
                                    >
                                        <div
                                            class="row"
                                            v-bind:key="'cart-item-' + index"
                                        >
                                            <div class="col-3">
                                                <StickerPreview
                                                    v-if="
                                                        Object.keys(item)
                                                            .length > 0
                                                    "
                                                    :model="item"
                                                    :background="
                                                        item.background
                                                    "
                                                    :sample="item.sample"
                                                    :sticker="item.sticker"
                                                />
                                            </div>
                                            <div class="col">
                                                <DeleteCart :id="item.id" />
                                                <div
                                                    class="small-text text-default"
                                                    v-if="item && item.sticker"
                                                >
                                                    {{ item.sticker.name }}
                                                </div>
                                                <div
                                                    class="small-text"
                                                    v-if="item.material === 1"
                                                >
                                                    {{
                                                        $t(
                                                            "Műanyag felületekre"
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    class="small-text"
                                                    v-if="item.material === 2"
                                                >
                                                    {{
                                                        $t("Textil felületekre")
                                                    }}
                                                </div>
                                                <div class="small-text">
                                                    {{ $t("30db / csomag") }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row mb-3"
                                            v-bind:key="'cart-sum-' + index"
                                        >
                                            <div class="col-3 offset-3">
                                                {{ item.quantity }} db
                                            </div>
                                            <div class="col text-end">
                                                {{ item.total }} Ft
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row border-top mt-3 pt-3">
                                        <div class="col-xl-12">
                                            <router-link
                                                to="/cart#checkout"
                                                class="btn btn-primary mb-2 w-100"
                                                >{{
                                                    $t("Pénztár")
                                                }}</router-link
                                            >
                                            <router-link
                                                to="/cart#cart"
                                                class="btn btn-secondary mb-2 w-100"
                                                >{{
                                                    $t("Kosár megtekintése")
                                                }}</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import StickerPreview from "./cart/StickerPreview";
import DeleteCart from "./cart/DeleteCart";

export default {
    name: "Header",
    data() {
        return {
            menu: [],
            loggedInModal: false,
            loggedOutModal: false,
            cartOpen: false,
            form: {
                email: null,
                password: null
            },
            cart: {
                items: []
            }
        };
    },
    components: { StickerPreview, DeleteCart },
    computed: {
        ...mapGetters(["currentUser", "isAuthenticated"]),
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        cartItems: function() {
            if (this.cart && this.cart.items) {
                return this.cart.items.length;
            }
            return 0;
        }
    },
    watch: {
        $route: {
            handler: function() {
                this.loggedOutModal = false;
                this.loggedInModal = false;
                this.cartOpen = false;
            },
            deep: true
        }
    },
    methods: {
        getMenu() {
            this.$http.get(`${this.url}/menu/get/1`).then(response => {
                this.menu = response.data.data;
            });
        },
        getCart() {
            const cartUUid = localStorage.getItem("cart_uuid");
            this.$http.get(`${this.url}/cart/${cartUUid}`).then(response => {
                localStorage.setItem("cart_uuid", response.data.data.uuid);
                this.cart = response.data.data;
            });
        },
        getCartAuth() {
            const cartUUid = localStorage.getItem("cart_uuid");
            this.$http
                .get(`${this.url}/cart/auth/${cartUUid}`)
                .then(response => {
                    localStorage.setItem("cart_uuid", response.data.data.uuid);
                    this.cart = response.data.data;
                });
        },
        openCustomModal() {
            if (this.isAuthenticated) {
                this.loggedInModal = !this.loggedInModal;
            } else {
                this.loggedOutModal = !this.loggedOutModal;
            }
        },
        openCartModal() {
            this.cartOpen = !this.cartOpen;
        },
        logout() {
            $cookies.remove("id_token");
            this.loggedOutModal = false;
            this.loggedInModal = false;
            this.$router.replace({ name: "Home" });
            Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
                JwtService.saveToken(JwtService.getToken());
            });
        },
        doLogin() {
            const self = this;
            this.$http.post(this.url + "/login", this.form).then(response => {
                $cookies.set(
                    "id_token",
                    response.data.token,
                    60 * 60 * 24 * 1000
                );
                Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
                    JwtService.saveToken(JwtService.getToken());
                });
                self.loggedOutModal = false;
                self.loggedInModal = false;
            });
        }
    },
    beforeMount() {
        const self = this;
        self.getMenu();
        if (self.isAuthenticated) {
            self.getCartAuth();
        } else {
            self.getCart();
        }
        self.$eventHub.$on("updateCart", function() {
            console.log("cart-update header");
            if (self.isAuthenticated) {
                self.getCartAuth();
            } else {
                self.getCart();
            }
        });
    },
    mounted() {
        $(document).ready(function() {
            $(document).on("click", ".menu-open", function() {
                $(this).toggleClass("active");
                $("#main-menu").toggleClass("active");
                $(".menu-close").toggleClass("d-none");
            });

            $(document).on("click", ".menu-close", function() {
                $(".menu-open").toggleClass("active");
                $("#main-menu").toggleClass("active");
                $(".menu-close").toggleClass("d-none");
            });
            $(document).on("click", ".navigation a", function() {
                $(".menu-open").removeClass("active");
                $("#main-menu").removeClass("active");
                $(".menu-close").addClass("d-none");
            });
        });
    }
};
</script>

<style scoped></style>
