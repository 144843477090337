import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { title: "Címkézz magadnak, légy egyedi! | Nevmatrica.hu" }
    },
    {
        path: "/contact",
        name: "Contact",
        meta: { title: "Kapcsolat | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
    },
    {
        path: "/blog",
        name: "Blog",
        meta: { title: "Blog | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "blog" */ "../views/Blog.vue")
    },
    {
        path: "/blog/:slug",
        name: "Article",
        meta: { title: "Cikkek | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "blog" */ "../views/Article.vue")
    },
    {
        path: "/profile/:tab?",
        name: "Profile",
        meta: {
            requiresAuth: true,
            title: "Profilom | Nevmatrica.hu"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
    },
    {
        path: "/cart",
        name: "Cart",
        meta: { title: "Kosár | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "cart" */ "../views/Cart.vue")
    },
    {
        path: "/creator",
        name: "Creator",
        meta: { title: "Matricatervező | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "creator" */ "../views/Creator.vue")
    },
    {
        path: "/registration",
        name: "Registration",
        meta: { title: "Regisztráció | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "registration" */ "../views/Registration.vue"
            )
    },
    {
        path: "/about",
        name: "About",
        meta: { title: "Rólunk | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/payment-finish",
        name: "PaymentFinish",
        meta: { title: "Sikeres fizetés | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "payment-finish" */ "../views/PaymentFinish.vue"
            )
    },
    {
        path: "/404",
        name: "404",
        meta: { title: "404 | Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "404" */ "../views/404.vue")
    },
    {
        path: "*",
        name: "Static",
        meta: { title: "Nevmatrica.hu" },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "Static" */ "../views/Static.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router;
