<template>
    <div class="home">
        <section class="hero light-bg hero-bg">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6">
                        <h1>
                            CÍMKÉZZ MAGADNAK
                            <div class="text-yellow">LÉGY EGYEDI!</div>
                        </h1>
                        <p class="my-4">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam nonummy nibh euismod.
                        </p>
                        <router-link
                            :to="{ name: 'Creator' }"
                            class="btn btn-primary"
                            >Matricatervező</router-link
                        >
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5">
            <div class="container">
                <div class="row align-items-center mb-5">
                    <div class="col-md-6 col-xl-3 text-xl-center my-2">
                        <img src="/img/icon1.svg" class="mini-icon" />100%
                        Termékgarancia
                    </div>
                    <div class="col-md-6 col-xl-3 text-xl-center my-2">
                        <img src="/img/icon2.svg" class="mini-icon" />Strapabíró
                    </div>
                    <div class="col-md-6 col-xl-3 text-xl-center my-2">
                        <img src="/img/icon3.svg" class="mini-icon" />Vízálló
                    </div>
                    <div class="col-md-6 col-xl-3 text-xl-center my-2">
                        <img src="/img/icon5.svg" class="mini-icon" />Ingyenes
                        szállítás
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div id="prod1" class="text-center">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="shape">
                                        <div class="h3">Névmatrica</div>
                                        <p>30x13MM</p>
                                        <router-link
                                            :to="{
                                                name: 'Creator',
                                                query: { type: 'square30x13' }
                                            }"
                                            class="btn btn-primary"
                                            >Matricatervező</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <div id="prod2" class="text-center">
                            <div class="shape">
                                <div class="h3">Minimatrica</div>
                                <p>16x6MM</p>
                                <router-link
                                    :to="{
                                        name: 'Creator',
                                        query: { type: 'square16x6' }
                                    }"
                                    class="btn btn-primary"
                                    >Matricatervező</router-link
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div id="prod3" class="text-center">
                            <div class="shape">
                                <div class="h3">Matricacsomag</div>
                                <p>16x6MM, 30x13MM</p>
                                <a href="#" class="btn btn-primary"
                                    >HAMAROSAN...</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="light-bg bg1">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="sub-title">Tervezd magad</div>
                        <h2 class="mb-100">
                            A ruhák, tárgyak egyszerű jelölése!
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6">
                        <p>
                            A címke elengedhetetlen megoldás also- és
                            felsőruházat, cipők, játékok, élelmiszer dobozok,
                            ivókulacsok és még sok más jelölésére óvodában,
                            iskolában.
                        </p>

                        <p>
                            A név címkével könnyű felismerni és megtalálni a
                            saját dolgaidat. Amikor új dolgot vásárolsz a
                            névmatrica egy nagyon kis befektetés arra, hogy ha
                            új télikabátot vagy cipőt kellene vásárolnod mert
                            gyermeked elveszítette. Kedvező megoldá,s mind a
                            pénztárcád mind pedig a környezeted számára.
                        </p>

                        <p>
                            A névcímkét vagy más néven ruházati matricát szinte
                            a családban fellelhető összes dologra fel tudod
                            használni. A névcimke ellenáll a mosógépnek és a
                            mosogatógépnek.
                        </p>
                    </div>
                    <div class="col-xl-6">
                        <p>
                            A matricák névjelölőként működnek és nagyon könnyen
                            használhatók - nem kell varrni vagy vasalni. Helyezd
                            a névmatricádat a ruhád cimkéjére vagy ha a ruhádon
                            nincs ilyen, tedd a mosási utasításra, amikor ruhán
                            használod.
                        </p>

                        <p>
                            Tervezd meg gyermekednek vagy magadnak saját egyedi
                            névmatricádat. Könnyen feltöltheted gyermeked képét
                            vagy akár házikedvencedét - de kedvenc figurádat is.
                            Egy személyes márkával könnyű megkülönböztettni,
                            látni, és láthatóvá tenni, a Te saját dolgaidat.
                        </p>

                        <p>
                            Ha nagyobb vagy kisebb jeöléseket szeretnél akkor
                            megváltoztathatod a méretet az oldalon.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-xl-12 text-center">
                        <router-link
                            :to="{ name: 'Creator' }"
                            class="btn btn-primary"
                            >Matricatervező</router-link
                        >
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-0">
            <div class="row">
                <div class="col-4">
                    <img src="/img/03.png" class="w-100" />
                </div>
                <div class="col-4">
                    <img src="/img/04.png" class="w-100" />
                </div>
                <div class="col-4">
                    <img src="/img/05.png" class="w-100" />
                </div>
            </div>
        </section>
        <section class="bg2">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 text-center">
                        <div class="sub-title">Tervezd magad</div>
                        <h2 class="mb-100">
                            A ruhák, tárgyak egyszerű jelölése!
                        </h2>
                    </div>
                </div>
                <div class="row mb-100">
                    <div class="col-4">
                        <img src="/img/06.png" class="w-100" />
                    </div>
                    <div class="col-4">
                        <img src="/img/07.png" class="w-100" />
                    </div>
                    <div class="col-4">
                        <img src="/img/08.png" class="w-100" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 offset-xl-3">
                        <p>
                            A névmatricák lágy és rugalmas anyagból készülnek
                            így gyermeked észre sem veszi amikor a ruháján van.
                            Ha meg kell jelölnöd gyermeked ruháit akkor ez csak
                            rendszeres matricával működik.
                        </p>

                        <p>
                            Tervezz egy egyedi matricát gyermekeddel együtt ami
                            csak az övé, így hosszú távon lehetővé teszed
                            gyermeked részére az önnállóságot. Az általa
                            tervezett matricával könnyen felismeri saját ruháit
                            már az óvodában is.
                        </p>

                        <p>
                            Önállóbban és szabadabban tud mozogni és nem kell
                            segítség, hogy megtalálja a saját ruháit.
                        </p>

                        <p>
                            Könnyebb felismerni számára egy általa tervezett
                            emblémát mint egy ovis jelet. Neked pedig egyszerűbb
                            megtalálni gyermeked ruháit ha benne van a neve.
                        </p>

                        <p>
                            Ha gyermeked elveszti bármikor visszatalál hozzád a
                            matricán szereplő, általad megadott adatok alapján -
                            mely lehet név, telefonszám vagy egy egyedileg
                            feltöltött kép.
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-xl-12 text-center">
                        <router-link
                            :to="{ name: 'Creator' }"
                            class="btn btn-primary"
                            >Matricatervező</router-link
                        >
                    </div>
                </div>
            </div>
        </section>
        <section class="light-bg">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="sub-title">Tervezd magad</div>
                        <h2 class="mb-100">
                            Miért fontos a névcimke használata
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 order-2 order-md-1">
                        <h2 class="text-uppercase mb-100">
                            Erősítsd meg gyermeked függetlenségét!
                        </h2>
                        <p>
                            Amikor a gyerekek elkezdik az óvodát vagy iskolát, a
                            legtöbb szülő tapasztalja, hogy a ruhák gyakran
                            elkeverednek, eltűnnek. <br />
                            A kis gyermekek még nem tudják megkülönböztetni a
                            ruháikat másokétól. Vagy egy nagy kupac névtelen
                            ruha között kell megkeresned gyermeked ruháját vagy
                            a pedagógusokat kell minden alkalommal bevonnod,
                            hogy meglegyen az aznap behozott kardigán, kabát
                            vagy éppen cipő.
                        </p>

                        <p>
                            Hosszú távon ez egy havi vagy heti kellemetlenség
                            vagy feszültség érzésé válik számodra. Drága, ha
                            gyereked elveszíti télikabátját, sapkáját vagy
                            kesztyűjét és újat kell vásárolni.
                        </p>
                    </div>
                    <div class="col-md-6 order-1 order-md-2">
                        <img src="/img/09.png" class="w-100" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <img src="/img/10.png" class="w-100" />
                    </div>
                    <div class="col-md-6">
                        <p>
                            Sokkal könnyebb gyermekednek és a pedagógusoknak is
                            ha tudják, hogy az adott ruhadarabot kinek a
                            fogasára kell felakasztanni. A gyermekekkel szembeni
                            elvárasokat az életkorhoz kell igazítani, egy írni
                            olvasni még nem tudó gyermek vizuális memoriája
                            sokkal könnyebben felismeri az általa tevezett
                            figurával, színnel ellátott ruhájára helyezett
                            cimkét, megjegyezze azt mintsem felismerje az összes
                            ruháját.
                        </p>

                        <p>
                            A nevével ellátott ruhával sokkal könnyebb
                            megtalálni a megfelelő tulajdonost. A nagyobb
                            gyermekek megtanulják, hogy gyorsan felismerjék
                            saját ruháikat, dolgaikat, ez növeli a gyermekek
                            függetlenségét, amikor saját dolgaikra kell
                            vigyázniuk. <br />
                            A motiváció és sikerélmény a gyermekeknél már ilyen
                            kis korban elkezdődik. A tudat, hogy a névcímkék
                            növelik gyermeked függetlenségét.
                        </p>
                        <div class="mt-5">
                            <router-link
                                :to="{ name: 'Creator' }"
                                class="btn btn-primary"
                                >Matricatervező</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg3">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h2 class="text-blue text-uppercase">
                            "A nagyobb függetlenség érzése"
                        </h2>
                    </div>
                    <div class="col-md-6 offset-md-2">
                        <p>
                            Számos jó oka van annak, hogy jeleket használj a
                            gyerekek ruhájában. Az óvodában gyakran több gyermek
                            is használ hasonló népszerű ruhamárkákat például
                            H&M, Pepco, vagy Kik.
                        </p>
                        <p>
                            Számos márka használ tetszetős rajzfilmfigurás,
                            feliratos, az adott korosztályban kedvelt design
                            ruhákat. Nagyobb függetlenséget add gyermekednek ha
                            magabiztosan tudja, hogy melyik kardigán, vagy éppen
                            cipő az ami az övé. Ezt nevezik “kis biztos
                            sikereknek” a gyermek mindennapi életében, ami
                            segíthet az esetleges, gyerekek közötti konfliktusok
                            kiküszöbölésében is.
                        </p>
                    </div>
                </div>
                <div class="row mt-100">
                    <div class="col-md-4">
                        <h2 class="text-blue text-uppercase">
                            "Vizuális memória és tanulás"
                        </h2>
                    </div>
                    <div class="col-md-6 offset-md-2">
                        <p>
                            Ha a gyermek még nem ismeri fel teljesen a nevét,
                            használhatsz csak plusz motívumokat/jeleket, ha
                            például a név mellett olyan alak található, mint egy
                            szív vagy egy virág, amely a gyerekek szinte első
                            vizuális élményeinek egyike.
                        </p>
                        <p>
                            A legtöbb gyermek azonban már 3-4 éves korában
                            felismeri a nevét, majd pedig a memóriájából elővéve
                            szinte az első írásos élmények egyike lesz. Szinte
                            az első szülői, gyermekünkkel kapcsolatos büszkeség
                            érzése az amikor gyermekünk 4-5 éves korában
                            felfedezzük, hogy “Anya, már le tudom írni a nevem
                            “.
                        </p>
                        <div class="mt-5">
                            <router-link
                                :to="{ name: 'Creator' }"
                                class="btn btn-primary"
                                >Matricatervező</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="light-bg">
            <b-carousel
                id="carousel-1"
                :interval="4000"
                controls
                :label-next="''"
                :label-prev="''"
            >
                <b-carousel-slide>
                    <template slot="img">
                        <div class="container">
                            <div class="row">
                                <div class="col-4">
                                    <img src="/img/11.png" class="w-100" />
                                </div>
                                <div class="col-4">
                                    <img src="/img/12.png" class="w-100" />
                                </div>
                                <div class="col-4">
                                    <img src="/img/13.png" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </template>
                </b-carousel-slide>
                <b-carousel-slide>
                    <template slot="img">
                        <div class="container">
                            <div class="row">
                                <div class="col-4">
                                    <img src="/img/20.png" class="w-100" />
                                </div>
                                <div class="col-4">
                                    <img src="/img/21.png" class="w-100" />
                                </div>
                                <div class="col-4">
                                    <img src="/img/22.png" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </template>
                </b-carousel-slide>
                <b-carousel-slide>
                    <template slot="img">
                        <div class="container">
                            <div class="row">
                                <div class="col-4">
                                    <img src="/img/23.png" class="w-100" />
                                </div>
                                <div class="col-4">
                                    <img src="/img/24.png" class="w-100" />
                                </div>
                                <div class="col-4">
                                    <img src="/img/25.png" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </section>
        <section class="bg4">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <p>
                            A névcímke egy okos kis matrica, amellyel nevet
                            adhatsz a ruháidnak és a dolgaidnak.
                        </p>
                        <p>
                            Még a kisebb gyermekek is felismerhetik a dolgaikat
                            a Névcímkével. Tegyél egy figurát a névcimkére, így
                            gyermeked könnyen megismerheti a saját dolgait, még
                            akkor is, ha még nem tud olvasni. A matricán
                            szereplő ábra megegyezhet az óvodában használt
                            jelekkel.
                        </p>
                        <p>
                            A névcímkével jelentősen megkönnyíthető a gyerekek
                            mindennapi élete. Ezenkívül a gyerekek
                            megtapasztalják a nagyobb önnállöság érzését amikor
                            ők maguk is felismerik ruhájukat.
                        </p>
                    </div>
                    <div class="col-xl-6">
                        <p>
                            Használhatom a névcímkét a ruhákon kívül? <br />
                            Igen! Sőt! A névcímke nagyon jól működik rengeteg
                            sok más dolognál, a ruhákon kívűl is. Rakhatod
                            játékok, élelmiszerdobozok, iskola táska, kulacs,
                            telefon és egyéb töltők, iPad, okostelefonok,
                            sportszerek, kerékpár, bukósisak, cipők és még sok
                            más dologra. Ha a ( saját márkádat ) élelmiszer
                            dobozra vagy kulacsra helyezed és mosogatógépben
                            mosod, azt is kibirja.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-xl-12 text-center">
                        <router-link
                            :to="{ name: 'Creator' }"
                            class="btn btn-primary"
                            >Matricatervező</router-link
                        >
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Home",
    components: {}
};
</script>
