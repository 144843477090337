<template>
    <div id="app">
        <Header />
        <div class="content">
            <div class="loader-layout">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="margin:auto;display:block;"
                    width="160px"
                    height="160px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                >
                    <g transform="translate(50 50)">
                        <g transform="scale(0.7)">
                            <g transform="translate(-50 -50)">
                                <g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        repeatCount="indefinite"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                        dur="0.625s"
                                    ></animateTransform>
                                    <path
                                        fill-opacity="0.8"
                                        fill="#f1bc68"
                                        d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                    ></path>
                                </g>
                                <g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        repeatCount="indefinite"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                        dur="0.8333333333333333s"
                                    ></animateTransform>
                                    <path
                                        fill-opacity="0.8"
                                        fill="#f57c69"
                                        d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                        transform="rotate(90 50 50)"
                                    ></path>
                                </g>
                                <g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        repeatCount="indefinite"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                        dur="1.25s"
                                    ></animateTransform>
                                    <path
                                        fill-opacity="0.8"
                                        fill="#5f9695"
                                        d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                        transform="rotate(180 50 50)"
                                    ></path>
                                </g>
                                <g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        repeatCount="indefinite"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                        dur="2.5s"
                                    ></animateTransform>
                                    <path
                                        fill-opacity="0.8"
                                        fill="#056e82"
                                        d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                        transform="rotate(270 50 50)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <router-view />
        </div>
        <Footer />
        <b-modal
            id="forgotPassword"
            :title="$t('Elfelejtett jelszó')"
            hide-footer
        >
            <div class="row">
                <div class="col-md-12">
                    <form>
                        <div class="form-floating mb-2" id="lostPwEmail">
                            <input
                                type="text"
                                name="text1"
                                class="form-control mb-2"
                                placeholder="E-mail"
                            />
                            <label for="lostPwEmail">E-mail</label>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary">
                                {{ $t("Küldés") }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </b-modal>
        <Cookie></Cookie>
    </div>
</template>

<script>
import Header from "./components/Header";
import Cookie from "./components/Cookie";
import Footer from "./components/Footer";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";

export default {
    components: { Header, Footer, Cookie },
    beforeMount() {
        Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
            JwtService.saveToken(JwtService.getToken());
        });
    }
};
</script>
