<template>
    <div
        class="preview-holder my-4"
        v-if="
            parseInt(sticker.background) === 1 || parseInt(sticker.figure) === 1
        "
    >
        <div
            class="preview"
            id="littleCartPreview"
            v-bind:class="{
                'flex-row-reverse': model.product_data.selectedSide === 1,
                round30x30: isRounded
            }"
            v-bind:style="previewStyle"
        >
            <div class="icon" v-if="model.product_data.allowedFigure">
                <img
                    :src="sampleUrl"
                    v-if="sampleUrl"
                    v-bind:style="iconHeight"
                />
            </div>
            <div class="text" :style="textStyle[0]" style="margin-top: 3%">
                <div id="text1"
                    :style="textStyle[1]"
                >
                    {{ model.product_data.text[0] }}
                </div>
                <div
                    id="text2"
                    v-if="parseInt(sticker.rows) > 1"
                    :style="textStyle[2]"
                >
                    {{ model.product_data.text[1] }}
                </div>
                <div
                    id="text3"
                    v-if="parseInt(sticker.rows) > 2"
                    :style="textStyle[3]"
                >
                    {{ model.product_data.text[2] }}
                </div>
            </div>
            <!-- <div class="text" :style="textStyle">
                <div id="text1"
                    :style="textStyle"
                >
                    {{ model.product_data.text[0] }}
                </div>
                <div
                    id="text2"
                    v-if="parseInt(sticker.rows) > 1"
                    :style="textStyle"
                >
                    {{ model.product_data.text[1] }}
                </div>
                <div
                    id="text3"
                    v-if="parseInt(sticker.rows) > 2"
                    :style="textStyle"
                >
                    {{ model.product_data.text[2] }}
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "StickerPreview",
    props: ["model", "background", "sample", "sticker"],
    data() {
        return {
            height: null,
            width: null,
            baseFontSize: null,
            imgHeight: null
        };
    },
    mounted() {
        const helper = this.sticker.height.split("/");
        this.width = $("#littleCartPreview").width();

        this.height = Math.floor(
            this.width /
                (parseFloat(helper[0].trim()) / parseFloat(helper[1].trim()))
        );
        this.imgHeight = parseFloat(this.sticker.img_height);
        this.baseFontSize = parseFloat(this.sticker.base_font_size);
    },
    computed: {
        isRounded() {
            if (this.sticker.sku.includes("round")) {
                return true;
            }
            return false;
        },
        /* textStyle() {
            let style = {};
            if (this.model.product_data.selectedFont) {
                style["font-family"] = this.model.product_data.selectedFont;
            }
            if (this.model.product_data.textColor) {
                style["color"] = this.model.product_data.textColor;
            }

            const baseFontSize = Math.floor(
                this.height / parseInt(this.baseFontSize)
            );
            const t1 = this.model.product_data.text[0]
                ? this.model.product_data.text[0].length
                : 0;
            const t2 = this.model.product_data.text[1]
                ? this.model.product_data.text[1].length
                : 0;
            const t3 = this.model.product_data.text[2]
                ? this.model.product_data.text[2].length
                : 0;

            const max = Math.max.apply(Math, [t1, t2, t3]);

            let fontSize = baseFontSize;

            if (max > 7) {
                fontSize = (this.height * 2.5) / max;
            }

            style["font-size"] = fontSize + "px";

            return style;
        }, */
        textStyle() {
            let style = {
                0: {},
                1: {},
                2: {},
                3: {},
            };
            if (this.model.product_data.selectedFont) {
                style[0]["font-family"] = this.model.product_data.selectedFont;
                style[1]["font-family"] = this.model.product_data.selectedFont;
                style[2]["font-family"] = this.model.product_data.selectedFont;
                style[3]["font-family"] = this.model.product_data.selectedFont;
            }
            if (this.model.product_data.textColor) {
                style[0]["color"] = this.model.product_data.textColor;
                style[1]["color"] = this.model.product_data.textColor;
                style[2]["color"] = this.model.product_data.textColor;
                style[3]["color"] = this.model.product_data.textColor;
            }
            const baseFontSize = Math.floor(
                (this.height / parseInt(this.baseFontSize))
            );
            const t1 = this.model.product_data.text[0] ? this.model.product_data.text[0].length : 0;
            const t2 = this.model.product_data.text[1] ? this.model.product_data.text[1].length : 0;
            const t3 = this.model.product_data.text[2] ? this.model.product_data.text[2].length : 0;

            let fontSize = [];
            fontSize[0] = baseFontSize;
            fontSize[1] = baseFontSize;
            fontSize[2] = baseFontSize;
            fontSize[3] = baseFontSize;

            if (t1 > 7) {
                if ((this.height * 2.5) / t1 < baseFontSize)  {
                    fontSize[1] = (this.height * 2.5) / t1;
                }
            }
            if (t2 > 7) {
                if ((this.height * 2.5) / t2 < baseFontSize)  {
                    fontSize[2] = (this.height * 2.5) / t2;
                }
            }
            if (t3 > 7) {
                if ((this.height * 2.5) / t3 < baseFontSize)  {
                    fontSize[3] = (this.height * 2.5) / t3;
                }
            }
            style[0]["font-size"] = fontSize[0]  + "px";
            style[1]["font-size"] = fontSize[1]  + "px";
            style[2]["font-size"] = fontSize[2]  + "px";
            style[3]["font-size"] = fontSize[3]  + "px";

            return style;
        },
        previewStyle() {
            let style = {};
            if (
                this.model.product_data.background &&
                this.model.product_data.allowedBackground
            ) {
                const bg = this.background;
                style["background-image"] = "url(" + bg.image + ")";
                style["background-color"] = "#fff";
            }
            if (this.model.product_data.backgroundColor) {
                style[
                    "background-color"
                ] = this.model.product_data.backgroundColor;
                style["background-image"] = "none";
            }
            if (this.model.product_data.customFileBackground) {
                style["background-image"] =
                    "url(" + this.model.product_data.customFileBackground + ")";
                style["background-color"] = "#fff";
            }
            style["height"] = this.height + "px";

            return style;
        },
        sampleUrl: function() {
            if (this.model.product_data.customFileSample) {
                return this.model.product_data.customFileSample;
            }

            if (this.model.product_data.sample) {
                return this.sample.image;
            }

            return false;
        },
        iconHeight: function() {
            return {
                height: Math.floor(this.height * this.imgHeight) + "px"
            };
        }
    }
};
</script>

<style scoped></style>
