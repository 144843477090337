<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <router-link :to="{ name: 'Home' }"
                        ><img src="/img/logo.svg" class="footer-logo" />
                    </router-link>
                    <ul class="navigation mb-3">
                        <template v-for="(item, index) in menu.items">
                            <!--<li>
                                <a
                                    href="#"
                                    class="cookie-button btn btn-dark"
                                    @click="showCookie = true"
                                >
                                    {{ cookieData.title }}
                                </a>
                            </li>-->
                            <li v-bind:key="'also-menu-' + index">
                                <router-link :to="item.link">{{
                                    item.title
                                }}</router-link>
                            </li>
                        </template>
                    </ul>
                    <small>© Copyright 2021 Névmatrica</small>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            menu: []
        };
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    beforeMount() {
        this.getMenu();
    },
    methods: {
        getMenu() {
            this.$http.get(`${this.url}/menu/get/2`).then(response => {
                this.menu = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
